.HeroSection {
    background-image: url('hero-background.jpg');
    background-size: cover;
    background-position: center;
    padding: 100px 20px;
    color: white;
    text-align: center;
  }

  .HeroSection h1 {
    font-size: 48px;
    text-shadow: 2px 2px 4px rgba(10, 10, 10, 0.753);
  }
  .HeroSection p {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .HeroSection input {
    padding: 10px;
    font-size: 18px;
  }
    
   .HeroSection select {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #ff6600;
    border:none;
    color:white;
    cursor: pointer;
   }

   .search-button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #ff6600;
    color:white;
    cursor: pointer;
   }