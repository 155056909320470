.CountriesSection {
    padding: 50px 20px;
    padding-bottom: 50px;
    text-align: center;
    background-color: #f1f2f2;
  }
  .CountriesSection h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .countries-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .countries-grid div {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  