.About {
    padding: 50px 20px;
    max-width: 800px;
    text-align: justify;
    margin: 0 auto; 
  }
  .About h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .About p {
    font-size: 18px;
    line-height: 1.6
  }
  .joinButton{
    background-color: #ff6600;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
  }
  
  