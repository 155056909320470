.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  .logo {
    font-size: 24px;
  }
  nav a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
  }
  nav .cta-button {
    background-color: #ff6600;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    color: white;
  }
  nav .login-link {
    margin-left: 15px;
    color: #ff6600;
  }
  
  