.UseCasesSection {
    padding: 50px 20px;
    text-align: center;
  }
  .UseCasesSection h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .use-cases {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .use-case {
    max-width: 200px;
  }
  .use-case h3 {
    font-size: 24px;
  }
  