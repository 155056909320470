.HowItWorksSection {
    padding: 50px 20px;
    padding-top: 0%;
    background-color: white;
    text-align: center;
    
  }
  .HowItWorksSection h2 {
    font-size: 36px;
    margin-bottom: 30px;
    
  }
  .steps {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .step {
    max-width: 200px;
  }
  .step h3 {
    font-size: 24px;
  }
  .GetRecommendedButton {
    padding: 10px 0px;
    font-size: 36px;
    background-color: #f1f2f2;
    color: #ff6600;
    border-style: solid;
    border-color: #c9c6c6;
    cursor: pointer;
    width: calc(50%);
   }

   .FindProfessionalsButton {
    width: calc(50%);
    padding: 10px 0px;
    font-size: 36px;
    background-color: #f1f2f2;
    color: #ff6600;
    border-style: solid;
    border-color: #c9c6c6;
    cursor: pointer;
   }

   .GetRecommendedButton.active {
    padding: 10px 20px;
    font-size: 36px;
    margin-bottom: 30px;
    background-color: white;
    color: black;
    border-style: none;
   }

   .FindProfessionalsButton.active {
    padding: 10px 20px;
    font-size: 36px;
    margin-bottom: 30px;
    background-color: white;
    color: black;
    border-style: none;
   }
  
  