.Login {
    padding: 50px 20px;
    max-width: 400px;
    margin: 0 auto;
  }
  .Login h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .Login form {
    display: flex;
    flex-direction: column;
  }
  .Login input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .Login button {
    padding: 10px;
    font-size: 18px;
    background-color: #ff6600;
    border: none;
    color: white;
    cursor: pointer;
  }
  