.Footer {
    padding: 50px 20px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
  }
  .footer-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  .social-media a {
    color: white;
    margin: 0 10px;
  }
  .newsletter input {
    padding: 10px;
    margin-right: 10px;
  }
  .newsletter button {
    padding: 10px 20px;
    background-color: #ff6600;
    border: none;
    color: white;
    cursor: pointer;
  }
  